import React, { useState, useEffect } from 'react';
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import useAppStore from '../appStore';
import { getPageConfig } from '../utils/index';
import './Header.scss';

function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const { title } = useAppStore();
  const [routeHistory, setRouteHistory] = useState([location.pathname]);
  const currentPageConfig = getPageConfig(location.pathname);

  let showBackButton = false;
  if (currentPageConfig.level > 1) {
    showBackButton = true;
  } else if (routeHistory.length > 1 && currentPageConfig.level > 1) {
    showBackButton = true;
  }

  useEffect(() => {
    setRouteHistory(prevHistory => {
      if (prevHistory[prevHistory.length - 1] !== location.pathname) {
        return [...prevHistory, location.pathname];
      }
      return prevHistory;
    });
  }, [location.pathname]);

  const backBtnOnClick = () => {
    if(routeHistory.length> 1) {
      setRouteHistory(prevHistory => prevHistory.slice(0, -1));
      navigate(-1);
    }else {
      const parentPath = currentPageConfig.parent;
      if(parentPath) {
        navigate(parentPath);
      }else {
        navigate('/')
      }
    }

  };

  return (
    <div className="horizontal-left header">
      {showBackButton && (
        <div className="horizontal-center header-back" onClick={backBtnOnClick}>
          <LeftOutlined />
        </div>
      )}
      <div className="horizontal-center header-title">{title}</div>
    </div>
  );
}

export default Header;
