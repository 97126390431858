// import logo from './logo.svg';
import './App.scss';
import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import MainLayout from './MainLayout';
import 'antd/dist/antd.min.css';
import { Fetch } from './utils/fetchData';

function App() {
    const initWxConfig = async () => {
        const wx = window.wx;
        if (!wx) {return; }
        const currentUrl = window.location.href.split('#')[0];
        const res = await Fetch.GET('/api/getSignature', { url: encodeURIComponent(currentUrl) })
        if (res.success) {
            const { appId, timestamp, nonceStr, signature } = res.data;
            // 使用得到的appId, timestamp, nonceStr和signature初始化微信JS-SDK
            wx.config({
                appId: appId,
                timestamp: timestamp,
                nonceStr: nonceStr,
                signature: signature,
                jsApiList: ['onMenuShareTimeline', 'onMenuShareAppMessage', 'chooseWXPay']
            });
            wx.ready(function(){
                console.log('wx 注册成功')
                // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
            });
            wx.error(function(res){
                console.log(res);
                // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
            });

        }
    }

    useEffect(() => {
        initWxConfig()
    }, []);

    return (
        <Router>
            <MainLayout />
        </Router>
    );
}

export default App;
