import React ,{useState} from 'react';
import { Form, Input, Button, DatePicker, Selector, TextArea, Toast } from 'antd-mobile';
import dayjs from 'dayjs';
import { Fetch } from '../utils/fetchData';
import { useNavigate } from 'react-router-dom';
const now = new Date()

export default function ActivityEditPage() {
  const navigate = useNavigate();
  const [data, setData] = useState('')

  const handleImportAct = async () => {
    const res = await Fetch.POST('/api/activity/import', {}, { message: data });
    if (res.status === 200) {
      navigate('/activity');
    }else {
      Toast.show(res.message)
    }
  }
  const handleReset = () => {
      setData('')
  }

  const onTextAreaChange = (value) => {
    setData(value)
  }

  const venues = [
    { label: '李宁羽毛球馆', value: '1' }
  ];

  return (
    <>
      <div className='container' >
        <TextArea
          placeholder={`
      请复制微信接龙信息（格式如下）：
      
      #接龙
      9.26（二）晚上20-22四块场地打球报名了
      1. 队员A
      2. 队员B
      3. 队员C
      `}
          value={data}
          style={{padding: 20}}
          autoSize={{ minRows: 20}}
          onChange={onTextAreaChange}
        />
      </div>
      <div className='horizontal-center footer'>
        <Button block size={'default'} style={{marginRight: 10}} color='default' onClick={handleReset}>重置</Button>
        <Button block size={'default'} color='primary' onClick={handleImportAct}>导入活动</Button>
      </div>

    </>
  );
}
