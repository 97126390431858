import React from 'react';
import { Card, List, Space } from 'antd-mobile';
import StatusTag from './StatusTag';
import { getWeekdayInChinese, formatTime } from '../utils/index';

function ActivityDetailCard({ activity, onClick }) {
  return (
    <Card onClick={onClick} style={{ background: '#F3F4F7', marginBottom: 15, fontSize: 14, position: 'relative' }}>
      <StatusTag activity={activity}/>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <span style={{fontWeight: 500}}>{activity.name} | {activity.club_name}</span>
      </div>
      <div style={{ padding: '10px 0' }}>
        <div>时间：<span>{`${activity.event_date} ${getWeekdayInChinese(activity.event_date)}`}</span>  {`${formatTime(activity.start_time)} - ${formatTime(activity.end_time)}`}</div>
        <div>场地：{activity.court_count} 块</div>
        <div>报名：共{activity.applicants.length}人</div>
      </div>
      <div>
        {/*<div>报名参与者：共{activity.applicants.length}人</div>*/}
        <span style={{color: '#8A8C91', fontSize: 12}}>{activity.applicants.map(a => a.name).join(', ')}</span>
        {/*<List>*/}
        {/*  {activity.applicants.map(applicant => (*/}
        {/*    <List.Item key={applicant.id+applicant.applyInfo} extra={`余额：${applicant.balance}`}>*/}
        {/*      <div style={{ display: 'inline-flex', gap: '10px' }}>*/}
        {/*        <span>{applicant.name}</span>*/}
        {/*        <span>({applicant.applyInfo})</span>*/}
        {/*      </div>*/}
        {/*    </List.Item>*/}
        {/*  ))}*/}
        {/*</List>*/}
      </div>
    </Card>
  );
}

export default ActivityDetailCard;
