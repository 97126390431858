import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Button, Modal, Image, List, Card, Selector, SearchBar, Form, Input, Toast } from 'antd-mobile';
import { Fetch } from '../utils/fetchData';
import { formatNumberToCurrency } from '../utils/index';
import { useNavigate } from 'react-router-dom';
import ActivityDetailCard from '../Components/ActivityDetailCard';
import IconButton from '../Components/iconButton';
import { RightOutlined, PlusCircleFilled, PayCircleFilled, MoneyCollectFilled } from '@ant-design/icons';
import useAppStore from '../appStore';
import './FinanceList.scss';

function FinanceList() {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState('all');
  const [financeAccounts, setFinanceAccounts] = useState({});
  const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();
  const { setTitle } = useAppStore();
  const formRef = useRef(null);

  const fetchUserAccounts = useCallback(async () => {
    const res = await Fetch.GET('/api/users', { limit: 200 });
    res.data = res.data.map(item => ({ ...item, balanceStr: formatNumberToCurrency(item.balance) }));
    setData(res.data);
  }, []);

  const fetchFinanceAccounts = useCallback(async () => {
    const res = await Fetch.GET('/api/financeAccounts');
    setFinanceAccounts(res.data);
  }, []);

  useEffect(() => {
    setTitle('财务管理');
    fetchUserAccounts();
    fetchFinanceAccounts();
  }, []);

  const handleUserClick = (accountId) => {
    handleNavigate(`/finance/user/${accountId}`);
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleRecharge = (user, isRefund = false) => {
    let text = isRefund ? '退款' : '充值';
    Modal.confirm({
      title: `账户${text}`,
      content: (
        <>
          <div style={{ padding: 10, fontSize: 16, fontWeight: 600 }} className="horizontal-between">
            <span>{user.name}</span>
            <span>余额：¥{user.balanceStr}</span>
          </div>
          <Form initialValues={{ amount: '' }} ref={formRef}>
            <Form.Item name="amount" label={`${text}金额`} rules={[{ required: true, message: `${text}金额不能为空` }]}>
              <Input placeholder="请输入金额" defaultValue={''} type="number" />
            </Form.Item>
          </Form>
        </>
      ),
      onConfirm: async function () {
        const values = formRef.current.getFieldsValue();
        let reqData = { amount: values.amount, accountId: user.account_id, isRefund };
        const res = await Fetch.POST('/api/account/userRecharge', {}, reqData);
        if (res.status === 200) {
          Toast.show({ content: user.name + `${text}成功` });
          formRef.current.resetFields();
          fetchUserAccounts();
          fetchFinanceAccounts();
        }
      }
    });
  };

  return (
    <div className="finance">
      <Card>
        <div style={{ fontSize: 18, fontWeight: 500, marginLeft: 10 }}>账户汇总</div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
          <IconButton IconCmp={MoneyCollectFilled} text={`现金账户`} desc={financeAccounts.cashAmount} bgColor="#E8FEEA" color="#25C244"
                      onClick={() => { handleNavigate('/finance/cash');}} />
          <IconButton IconCmp={PayCircleFilled} text={`场馆账户`} desc={financeAccounts.venueAmount} bgColor="#E6EFFD" color="#4F7BFE"
                      onClick={() => { handleNavigate('/finance/venue');}} />
          <IconButton IconCmp={PlusCircleFilled} text={`待收款`} desc={financeAccounts.borrowAmount} bgColor="#FDF5E6" color="#FE9B31"
                      onClick={() => { handleNavigate('/finance/borrow');}} />
        </div>
      </Card>
      <Card style={{ marginTop: 15, padding: 0 }}>
        <div className="horizontal-between" style={{ padding: '0 20px', marginBottom: 10 }}>
          <div style={{ fontSize: 18, fontWeight: 500 }}>会员账户</div>
        </div>
        <div className="horizontal-between" style={{ padding: '0 20px', marginBottom: 15 }}>
          <SearchBar
            style={{ width: '58%' }}
            placeholder="输入会员名可筛选"
            value={searchText}
            onChange={v => {
              setSearchText(v);
            }}
          />
          <Selector
            style={{
              '--border-radius': '5px',
              '--border': 'solid transparent 1px',
              '--checked-border': 'solid var(--adm-color-primary) 1px',
              '--padding': '4px 12px',
            }}
            options={[
              {
                label: '全部',
                value: 'all',
              },
              {
                label: '欠费',
                value: 'borrow',
              }
            ]}
            value={[filter]}
            onChange={v => v.length && setFilter(v[0])}
          />
        </div>

        <List>
          {data.filter(user => {
            if (searchText) {
              let a = searchText.toLowerCase();
              let b = user.name.toLowerCase();
              return b.includes(a);
            }
            if (filter === 'all') {
              return true;
            } else if (filter === 'borrow') {
              return user.balance < 0;
            }
          }).map(user => (
            <List.Item key={user.name} onClick={() => {handleUserClick(user.account_id);}}>
              <div className="user-item">
                <span>{user.name}</span>
                <span style={{ color: user.balance < 0 ? 'red' : '' }}>
                  <span>¥{user.balanceStr}</span>
                  <Button style={{ marginLeft: 15 }} onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleRecharge(user);
                  }}>充值</Button>
                  <Button style={{ marginLeft: 15 }} onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleRecharge(user, true);
                  }}>退款</Button>
                </span>
              </div>
            </List.Item>
          ))}
        </List>
      </Card>
    </div>
  );
}

export default FinanceList;
