import React, { useEffect } from 'react';
import { Route, Link, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { TabBar } from 'antd-mobile';
import ActivityList from './Pages/ActivityList';
import ActivityEndedList from './Pages/ActivityEndedList';
import ActivityDetailPage from './Pages/ActivityDetailPage';
import { UserOutlined, WalletOutlined, AppstoreOutlined, UserSwitchOutlined, LeftOutlined } from '@ant-design/icons';
import ActivityEditPage from './Pages/ActivityEditPage';
import ActivityAddPage from './Pages/ActivityAddPage';
import FinanceList from './Pages/FinanceList';
import FinanceCash from './Pages/FinanceCash';
import FinanceVenue from './Pages/FinanceVenue';
import FinanceBorrow from './Pages/FinanceBorrow';
import TransactionList from './Pages/TransactionList';
import UserList from './Pages/UserList';
import Header from './Components/Header';
import { getPageConfig } from './utils';
import './MainLayout.scss'


function MainLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const pageConfig = getPageConfig(location.pathname);
  console.log(pageConfig);
  console.log(location.pathname)
  const setActiveKey = (key) => {
    console.log(key);
    navigate(key);
  };

  return (<div style={{ position: 'fixed', height: '100%', width: '100%', top: 0, background: '#F2F2F4' }}>
    <Header />
    <div style={{
      padding: '15px',
      overflow: 'hidden',
      overflowY: 'auto',
      position: 'absolute',
      bottom: pageConfig.level === 1 ? 'calc(env(safe-area-inset-bottom) + 65px)' : 0,
      top: 50,
      left: 0,
      right: 0
    }}>
      <Routes>
        <Route path="/" element={<Navigate to="/activity" replace />} />
        <Route path="/activity" element={<ActivityList />} />
        <Route path="/activity/ended" element={<ActivityEndedList />} />
        <Route path="/activity/:id" element={<ActivityDetailPage />} />
        <Route path="/activity/edit/:id" element={<ActivityEditPage />} />
        <Route path="/activity/add" element={<ActivityAddPage />} />
        <Route path="/finance" element={<FinanceList />} />
        <Route path="/finance/cash" element={<FinanceCash />} />
        <Route path="/finance/venue" element={<FinanceVenue />} />
        <Route path="/finance/borrow" element={<FinanceBorrow />} />
        <Route path="/finance/user/:id" element={<TransactionList />} />
        <Route path="/members" element={<UserList />} />
        <Route path="/members/edit" element={<UserList />} />
      </Routes>
    </div>
    {pageConfig.level === 1 &&
     <TabBar style={{ position: 'absolute', bottom: 0, left: 0, right: 0, background: 'white', paddingBottom: 'calc(env(safe-area-inset-bottom) + 10px)', paddingTop: 5 }}
             unselectedTintColor="#949494"
             tintColor="#33A3F4"
             barTintColor="white"
             activeKey={pageConfig.key}
             onChange={setActiveKey}>
       <TabBar.Item
         title="活动"
         key="activity"
         icon={<AppstoreOutlined />}
         selectedIcon={<AppstoreOutlined />}
       />
       <TabBar.Item
         title="财务"
         key="finance"
         icon={<WalletOutlined />}
         selectedIcon={<WalletOutlined />}
       />
       <TabBar.Item
         title="会员"
         key="members"
         icon={<UserOutlined />}
         selectedIcon={<UserOutlined />}
       />
       {/*<TabBar.Item*/}
       {/*  title="我的"*/}
       {/*  key="mine"*/}
       {/*  icon={<UserSwitchOutlined />}*/}
       {/*  selectedIcon={<UserSwitchOutlined />}*/}
       {/*/>*/}
     </TabBar>}
  </div>);
}

export default MainLayout;
