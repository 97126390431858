import { formatNumberToCurrency } from '../utils/index';
function IconButton({ IconCmp, text, bgColor, color,desc, onClick = () => {} }) {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '15px 0 10px 0',
      width: 80,
      color: '#363A41',
      // border: '1px solid #2958FF',
      borderRadius: '4px',
      fontSize: 13
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: 42,
        height: 42,
        background: bgColor,
        color: color,
        // border: '1px solid #2958FF',
        borderRadius: '10px',
        marginBottom: 5
      }}
           onClick={onClick}>
        <IconCmp style={{ fontSize: 28 }} />
      </div>

      <span style={{marginTop: 3}}>{text}</span>
      {desc !== undefined && desc !== null && <span style={{ color: desc < 0 ? 'red' : '', fontSize: 16, fontFamily: "'din-bold-2', Arial, Helvetica, sans-serif", marginTop: 5 }}>¥{formatNumberToCurrency(desc)}</span>}
    </div>
  );
}

export default IconButton;
