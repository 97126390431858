import React, { useCallback, useEffect, useState } from 'react';
import { Button, Input, Modal, Card, Toast } from 'antd-mobile';
import { Fetch } from '../utils/fetchData';
import { useNavigate } from 'react-router-dom';
import ActivityDetailCard from '../Components/ActivityDetailCard';
import IconButton from '../Components/iconButton';
import { RightOutlined, PlusCircleFilled, PayCircleFilled, BulbFilled, FormOutlined } from '@ant-design/icons';
import useAppStore from '../appStore';

function ActivityList() {
  const [password, setPassword] = useState('');
  const [visible, setVisible] = useState(false);
  const correctPassword = 'a0172024';

  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const { setTitle } = useAppStore();

  function handleActivityClick(activityId) {
    navigate(`/activity/${activityId}`);
  }

  const fetchActivities = useCallback(async () => {
    const res = await Fetch.GET('/api/activities', { status: 0 });
    setData(res.data);
  }, []);

  useEffect(() => {
    let password = localStorage.getItem('adminPassword');
    if (password !== correctPassword) {
      setVisible(true);
    }
    setTitle('活动列表');
    fetchActivities();
  }, []);

  const handleConfirm = () => {
    if (password === correctPassword) {
      localStorage.setItem('adminPassword', password);
      Toast.show({
        content: 'A017管理员认证成功',
      });
      setVisible(false); // 关闭弹窗
    } else {
      Toast.show({
        content: '密码错误',
      });
    }
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <>
      <Modal
        visible={visible}
        title="管理员验证"
        onClose={handleConfirm}
        closeOnAction
        content={
          <Input
            clearable
            type="password"
            value={password}
            onChange={setPassword}
            placeholder="请输入管理员密码"
          />
        }
        actions={[
          {
            key: 'confirm',
            text: '确认',
            primary: true,
          },
        ]}
      />
      <Card>
        <div style={{ fontSize: 18, fontWeight: 500 }}>常用功能</div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
          <IconButton IconCmp={PlusCircleFilled} text="添加活动" bgColor="#FDF5E6" color="#FE9B31"
                      onClick={() => handleNavigate('/activity/add')} />
          <IconButton IconCmp={BulbFilled} text="采购" bgColor="#E8FEEA" color="#25C244"
                      onClick={() => handleNavigate('/finance/cash')} />
          <IconButton IconCmp={PayCircleFilled} text="场馆充值" bgColor="#E6EFFD" color="#4F7BFE"
                      onClick={() => handleNavigate('/finance/venue')} />
        </div>
      </Card>
      <Card style={{ marginTop: 15, minHeight: 200 }}>
        <div style={{ fontSize: 18, fontWeight: 500 }}>活动管理</div>
        <div onClick={() => handleNavigate('/activity/ended')}
             style={{
               display: 'flex',
               flexDirection: 'row',
               justifyContent: 'space-between',
               flexWrap: 'wrap',
               alignItems: 'center',
               margin: '15px 0',
               padding: 10,
               width: '100%',
               height: 40,
               background: '#F2F4FD',
               color: '#2958FF',
               // border: '1px solid #2958FF',
               borderRadius: '4px',
               fontSize: 14
             }}>
          <span>查看已完成活动</span>
          <RightOutlined />
        </div>
        {data.map(activity => (
          <ActivityDetailCard key={activity.event_id} onClick={() => handleActivityClick(activity.event_id)}
                              activity={activity} />
        ))}
        {data.length === 0 &&
         <div style={{ background: '#F2F4FD', color: '#486ee8', border: '1px dashed #c4cff3', minHeight: 160, borderRadius: 10, fontSize: 16 }}
              className="horizontal-center">
           <FormOutlined style={{marginRight: 10}}/>
           暂无活动，快来<Button style={{ margin: 10 }} size="small" color="primary" onClick={() => handleNavigate('/activity/add')}>添加</Button>一个吧
         </div>}
      </Card>
    </>
  );
}

export default ActivityList;
