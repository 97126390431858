import React, { useCallback, useState,useEffect, useRef } from 'react';
import { Form, Input, Button, Card, Toast } from 'antd-mobile';
import { Fetch } from '../utils/fetchData';
import { formatNumberToCurrency } from '../utils/index';
import { useNavigate } from 'react-router-dom';
import useAppStore from '../appStore';

const now = new Date();

export default function FinanceCash() {
  const navigate = useNavigate();
  const formRef = useRef(null);
  const [financeAccounts, setFinanceAccounts] = useState({})
  const [transactions, setTransactions] = useState([])
  const { setTitle } = useAppStore();
  const onFinish = async (values) => {
    const eventData = {
      amount: values.amount
    };
    console.log(values);
    console.log(eventData);

    try {
      const res = await Fetch.POST('/api/account/venueRecharge', {}, eventData);
      if (res.status === 200) {
        // navigate('/activity');
        Toast.show({ content: '场馆储蓄卡充值成功' });
        formRef.current.resetFields();
        await fetchFinanceAccounts()
      }
    } catch (error) {
      console.error('Error creating event:', error);
    }
  };


  const fetchTransactions = useCallback(async (accountId) => {
    const res = await Fetch.GET('/api/transactions', {limit: 300, account_id: accountId});
    setTransactions(res.data)
  }, [])


  const fetchFinanceAccounts = useCallback(async () => {
    const res = await Fetch.GET('/api/financeAccounts');
    const accountInfo = res.data;
    setFinanceAccounts(accountInfo);
    await fetchTransactions(accountInfo.venueAccount.id)
  }, [])

  useEffect(() => {
    setTitle('场馆充值')
    fetchFinanceAccounts()
  }, [])


  const venues = [
    { label: '羽毛采购', value: '1' },
    { label: '奖品采购', value: '2' },
    { label: '聚餐', value: '3' }
  ];

  return (
    <div>
      <Card>
        <div style={{ fontSize: 18, fontWeight: 500, marginBottom: 10, padding: '0 16px', borderBottom: '1px solid #eee' }} className='horizontal-between'>
          <span>场馆账户</span>
          <span style={{fontSize: 16}}>
            <span style={{fontSize: 25,fontFamily: 'BebasNeue'}}>¥{formatNumberToCurrency(financeAccounts.venueAmount)}</span>
          </span>
        </div>
        <div style={{ fontSize: 18, fontWeight: 500, marginBottom: 10, padding: '0 16px' }} className='horizontal-between'>
          <span>现金账户</span>
          <span style={{fontSize: 16}}>
            <span style={{fontSize: 25,fontFamily: 'BebasNeue'}}>¥{formatNumberToCurrency(financeAccounts.cashAmount)}</span>
          </span>
        </div>
        <Form onFinish={onFinish}
              ref={formRef}
              initialValues={{
                amount: ''
              }}
              footer={
                <Button block type="submit" color="primary" size="large">
                  确认充值
                </Button>
              }
        >
          <Form.Item name="amount" label="场馆充值金额" rules={[{ required: true, message: '充值金额不能为空' }]}>
            <Input placeholder="请输入金额" defaultValue={''} type="number" />
          </Form.Item>
        </Form>
      </Card>
      <div style={{ height: 20 }}></div>
      <Card>
        <div style={{padding: '0 10px'}}>
          <div style={{ fontSize: 18, fontWeight: 500, margin: '10px 0' }} className='horizontal-between'>
            <span>历史账单</span>
          </div>
          {/*<span style={{color: '#a9a9a9'}}>暂无历史单据</span>*/}
          {transactions.map(t => {
            return <Card style={{ background: '#F3F4F7', marginBottom: 15, marginTop: 15, fontSize: 14, position: 'relative' }}>
              <div   style={{color: '#333'}}> {t.transaction_detail}</div>
              <div style={{color: '#a9a9a9'}}> {t.transaction_time}</div>
            </Card>
          })}
          {transactions.length === 0 && <Card style={{ background: '#F3F4F7', marginBottom: 15, marginTop: 15, fontSize: 14, position: 'relative' }}>
            <span style={{ color: '#a9a9a9' }}>暂无历史单据</span>
          </Card>
          }
        </div>
      </Card>
    </div>
  );
}
