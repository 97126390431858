// ActivityDetailPage.jsx
import React, { useCallback, useEffect, useState } from 'react';
import { Fetch } from '../utils/fetchData';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Toast, Button, Modal } from 'antd-mobile';
import StatusTag from '../Components/StatusTag';

import useAppStore from '../appStore';
import './ActivityDetailPage.scss';
import { activityStatus, formatTime } from '../utils';

function formatApplicants(activityRes, budget) {
  let updatedBalances = {};
  activityRes.applicants = activityRes.applicants.map(user => {
    user.budget_balance = user.balance;
    if (updatedBalances.hasOwnProperty(user.id)) {
      user.budget_balance = updatedBalances[user.id];
    }
    const userBalanceAfter = user.budget_balance - budget.feePerPerson;
    updatedBalances[user.id] = userBalanceAfter;
    user.budget_balance = userBalanceAfter;
    return user
  });
  return activityRes
}

function ActivityDetailPage({ match }) {
  const [activity, setActivity] = useState(null);
  const [status, setStatus] = useState('');
  const [cost, setCost] = useState(null);
  const { id: activityId } = useParams();
  const { setTitle } = useAppStore();
  const navigate = useNavigate();

  const fetchActivities = useCallback(async () => {
    const budget = await Fetch.GET(`/api/activity/budget/${activityId}`);
    setCost(budget.data);

    const res = await Fetch.GET(`/api/activities`, { id: activityId });
    const activityRes = formatApplicants(res.data[0], budget.data)
    const status = activityStatus(res.data[0]);
    setActivity(activityRes);
    setStatus(status);
  }, []);

  useEffect(() => {
    setTitle('活动详情');
    fetchActivities();
  }, []);

  const handleRemoveAct = async () => {
    await Modal.confirm({
      content: '确定要删除吗？',
      onConfirm: () => {
        Fetch.DELETE(`/api/activity/${activityId}`)
            .then(res => {
              if (res.status === 200) {
                Toast.show({ content: '删除成功' });
                navigate('/activity');
              } else {
                Toast.show({ content: '删除失败' });
              }
            });
      }
    })
  };
  const handleUpdateAct = () => {
    navigate('/activity/add');
  }
  const handleUpdateActByForm = () => {
    navigate(`/activity/edit/${activityId}`);
  }

  const handleSettle = async () => {
    const res = await Fetch.GET(`/api/activity/settle/${activityId}`);
    if (res.status === 200) {
      Toast.show({ content: '结算成功' });
      navigate('/activity');
    } else {
      Toast.show({ content: '结算失败' });
    }
  };

  const handleReset = async () => {
    const res = await Fetch.GET(`/api/activity/reset/${activityId}`);
    if (res.status === 200) {
      Toast.show({ content: '撤销结算成功' });
      navigate('/activity');
    } else {
      Toast.show({ content: '撤销结算失败' });
    }
  };

  if (!activity) {
    return <></>;
  }

  return (
    <>
      <div className="container">
        <Card style={{ background: '#ffffff', fontSize: 15, padding: '5px 20px', position: 'relative' }}>
          <StatusTag activity={activity} />
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <span style={{ fontWeight: 500 }}>{activity.name} | {activity.club_name}</span>
          </div>
          <div style={{ paddingTop: 10 }}>
            <div>时间：<span><span>{activity.event_date}</span>&nbsp; {`${formatTime(activity.start_time)} - ${formatTime(activity.end_time)}`}</span>
            </div>
            <div>场地：{activity.court_count} 块</div>
            <div>报名：共{activity.applicants.length}人</div>

            {cost && <div className="horizontal-left detail-budget">
              <div className="detail-budget-header horizontal-left">费用预算</div>
              <div className="detail-budget-card horizontal-between">
                <div style={{ width: 160 }} className="horizontal-left">
                  <div style={{ width: 75 }}>球费：</div>
                  ¥{cost.totalBallFee}</div>
                <div style={{ width: 160 }} className="horizontal-left">
                  <div style={{ width: 75 }}>场地费：</div>
                  ¥{cost.totalCourtFee}</div>
                <div style={{ width: 160 }} className="horizontal-left">
                  <div style={{ width: 75 }}>总费用：</div>
                  ¥{cost.totalFee}</div>
                <div style={{ width: 160 }} className="horizontal-left">
                  <div style={{ width: 75 }}>人均费用：</div>
                  ¥{cost.feePerPerson}</div>
              </div>
            </div>
            }
          </div>
          <div style={{ paddingBottom: 15 }}>
            <div className="horizontal-between detail-header">
              <div style={{ width: 100, marginLeft: 15 }}>报名</div>
              <span style={{ width: 100, marginLeft: 15 }}>{status !== '已结算' ? '余额' : '消费'}</span>
              {cost && status !== '已结算' && <span style={{ width: 100, marginLeft: 15 }}>结算后余额</span>}
            </div>
            {activity.applicants.map((a, idx) => {
              let name = a.name + (a.applyInfo === 'self' ? '' : a.applyInfo);
              return <div key={idx} style={{ height: 35, fontSize: 14, width: '100%', borderBottom: '2px solid #fff', background: '#f6f6f6' }}
                          className="horizontal-between ">
                <div style={{ width: 100, marginLeft: 15 }}>{name}</div>
                {cost &&
                 <div style={{ width: 100, marginLeft: 15, color: status !== '已结算' && a.balance < 0 ? 'red' : '' }}>{status !== '已结算' ? a.balance
                   : cost.feePerPerson}
                 </div>}
                {cost && status !== '已结算' && <div style={{ width: 100, marginLeft: 15, color: (a.budget_balance) < 0 ? 'red' : '' }}>
                  {a.budget_balance}
                </div>
                }
                {/*<span style={{ width: 100, marginLeft: 15, color: '#333' }}>{`${a.gender === 1 ? '男🍀' : a.gender === 0 ? '女🌸' : '🤔?'}`}</span>*/}
              </div>;
            })}
          </div>
        </Card>
      </div>
      <div className="horizontal-center footer">
        {(status === '活动中' || status === '报名中' || status === '待结算') &&
         <>
           <Button block style={{ marginRight: 10 }} color="danger" fill="outline" onClick={handleRemoveAct}>删除</Button>
           {/*<Button block style={{ marginRight: 10 }} color="primary"  onClick={handleUpdateAct}>活动更新</Button>*/}
           <Button block style={{ marginRight: 10 }} color="primary"  onClick={handleUpdateActByForm}>编辑</Button>
         </>
        }
        {(status === '待结算') && <Button block color="primary" onClick={handleSettle}>结算</Button>}
        {(status === '已结算') && <Button block color="danger" fill='outline' onClick={handleReset}>撤销结算</Button>}
      </div>
    </>
  );
}

export default ActivityDetailPage;
