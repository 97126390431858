import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal, Card, SearchBar } from 'antd-mobile';
import { Fetch } from '../utils/fetchData';
import { useNavigate } from 'react-router-dom';
import ActivityDetailCard from '../Components/ActivityDetailCard';
import IconButton from '../Components/iconButton';
import { RightOutlined, PlusCircleFilled, PayCircleFilled, BulbFilled } from '@ant-design/icons';
import useAppStore from '../appStore';

function filterByDate(list, inputDate) {
    if (!inputDate) {
        return list;
    }
    // 将输入的日期格式转换为与 event_date 相同的格式
    const inputParts = inputDate.split('.');
    let formattedInputDate;

    if (inputParts.length === 3) {
        // 输入包含年份
        formattedInputDate = `${inputParts[0]}-${inputParts[1]}-${inputParts[2]}`;
    } else {
        // 输入不包含年份，只有月和日
        formattedInputDate = `-${inputParts[0]}-${inputParts[1]}`;
    }

    // 筛选数组中符合条件的元素
    return list.filter(item => {
        if (inputParts.length === 3) {
            // 如果输入包含年份，直接比较完整日期
            return item.event_date === formattedInputDate;
        } else {
            // 如果输入不包含年份，只比较月和日
            return item.event_date.includes(formattedInputDate);
        }
    });
}

function ActivityList() {
    const [data, setData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const navigate = useNavigate();
    const { setTitle } = useAppStore();

    function handleActivityClick(activityId) {
        navigate(`/activity/${activityId}`);
    }

    const fetchActivities = useCallback(async () => {
        const res = await Fetch.GET('/api/activities', { status: 1 });
        setData(res.data);
    }, []);

    useEffect(() => {
        setTitle('活动列表');
        fetchActivities();
    }, []);

    const handleDelete = async (id) => {
        Modal.confirm({
            title: '删除当前活动',
            content: `请确认是否要删除[id:${id}]的活动`,
            cancelText: '取消',
            okText: '确认',
            onOk: async () => {
                const res = await Fetch.DELETE(`/api/activity/${id}`);
                if (res.status === 200) {
                    fetchActivities();
                    Modal.success({
                        content: `活动${id}删除成功`
                    });
                }
            }
        });
    };

    const STATUS_MAP = (record) => {
        const MAP = {
            '1': {
                txt: '正常',
                color: 'green'
            },
            '0': {
                txt: '取消',
                color: 'red'
            },
            '2': {
                txt: '未开始',
                color: 'gray'
            }
        };
        return MAP[record.status];
    };

    const handleNavigate = (path) => {
        navigate(path);
    };

    const dataList = filterByDate(data,searchText)

    return (
        <>
            <Card style={{ marginTop: 15 }}>
                <div style={{ fontSize: 18, fontWeight: 500, marginBottom: 10 }}>已结算活动</div>
                <SearchBar
                    style={{
                        width: '100%',
                        marginBottom: 10
                    }}
                    placeholder="输入日期可筛选，例:12.30"
                    value={searchText}
                    onChange={v => {
                        setSearchText(v);
                    }}
                />
                {dataList.map(activity => (
                    <ActivityDetailCard key={activity.event_id}
                                        onClick={() => handleActivityClick(activity.event_id)}
                                        activity={activity} />
                ))}
            </Card>
        </>
    );
}

export default ActivityList;
