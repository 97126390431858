import React, { useCallback, useEffect, useState } from 'react';
import { Form, Input, Button, DatePicker, Selector, Card, SpinLoading, Picker, Tag, Toast } from 'antd-mobile';
import UserSelector from '../Components/UserSelector';
import dayjs from 'dayjs';
import { Fetch } from '../utils/fetchData';
import { useParams, useNavigate } from 'react-router-dom';
import useAppStore from '../appStore';
import { activityStatus } from '../utils';

const createTimeOptions = (start, end) => {
  const options = [];
  // 为了生成从00:00到23:00的时间选项，确保循环遍历到23
  for (let hour = start; hour < end; hour++) {
    const label = `${hour.toString().padStart(2, '0')}:00`;
    options.push({ label, value: label });
  }
  return options;
};

const timeColumns = [
  createTimeOptions(0, 24), // 起始时间范围，从00:00开始
  createTimeOptions(0, 24), // 结束时间范围，到23:00结束
];

function transformObject(input) {
  // 将日期字符串转换为Date对象，然后获取其时间戳
  const dateTimestamp = new Date(input.date).getTime();

  // 从输入对象中提取开始和结束时间
  const startTime = input.timeRange[0];
  const endTime = input.timeRange[1];

  // 构造并返回新的对象
  return {
    date: dateTimestamp,
    venue_id: input.venue[0],
    start_time: startTime,
    end_time: endTime,
    court_count: input.courtCount,
    created_userId: 34,  // 根据需求硬编码为34
    club_id: 1,           // 根据需求硬编码为1
    applicants: input.applicants
  };
}

export default function ActivityEditPage() {
  const navigate = useNavigate();
  const { setTitle } = useAppStore();
  const { id: activityId } = useParams();
  const [activity, setActivity] = useState(null);
  const [status, setStatus] = useState('');

  const fetchActivities = useCallback(async () => {
    const res = await Fetch.GET(`/api/activities`, { id: activityId });
    const activityRes = res.data[0];
    const status = activityStatus(activityRes);
    setActivity(activityRes);
    setStatus(status);
  }, []);

  useEffect(() => {
    setTitle('李宁羽毛球馆 | A017');
    fetchActivities();
  }, []);

  const onFinish = async (values) => {
    const eventData = transformObject(values);
    try {
      const res = await Fetch.POST(`/api/activity/update/${activityId}`, {}, eventData);
      if (res.status === 200) {
        Toast.show({ content: '更新成功' });
        setTimeout(() => {
          navigate(-1);
        }, 500);
      }
    } catch (error) {
      console.error('Error creating event:', error);
    }
  };

  const venues = [
    { label: '李宁羽毛球馆', value: '1' }
  ];

  const handleWxLogin = async () => {
    window.location.href = '/api/wechat-login'; // 使用返回的URL进行重定向
  };
  const handlePayClick = async () => {
    try {
      // 向后端请求支付参数
      const response = await Fetch.POST('/api/createOrder');
      debugger
      if (response.data) {
        const { appId, timestamp, nonceStr, packageString, signType, paySign } = response.data;
        if (window.WeixinJSBridge) {
          window.WeixinJSBridge.invoke(
            'getBrandWCPayRequest', {
              'appId': appId, // 公众号ID
              'timeStamp': timestamp,
              'nonceStr': nonceStr,
              'package': packageString,
              'signType': signType,
              'paySign': paySign
            },
            function (res) {
              if (res.err_msg == 'get_brand_wcpay_request:ok') {
                // 支付成功
              } else {
                // 支付失败
              }
            }
          );
        }
      }
    } catch (error) {
      console.error('Error during payment:', error);
    }
  };

  const defaultValue = activity ? {
    date: new Date(activity.event_date),
    venue: [`${activity.venue_id}`],
    startTime: activity.start_time,
    endTime: activity.end_time,
    timeRange: [activity.start_time, activity.end_time],
    courtCount: activity.court_count,
    totalBallFee: '',
    applicants: activity.applicants.map(o => `${o.name} ${o.applyInfo}`)
  } : {};

  return (
    <>
      {!activity && <Card style={{ background: '#F3F4F7', marginBottom: 15, marginTop: 15, fontSize: 14, position: 'relative' }}>
        <div className="horizontal-left">
          <SpinLoading color="primary" style={{ '--size': '24px', marginLeft: 10 }} />
          <span style={{ marginLeft: 20 }}>数据加载中请稍后...</span>
        </div>
      </Card>}
      {activity && <Form onFinish={onFinish}
                         initialValues={defaultValue}
                         layout='horizontal'
                         footer={
                           <Button block type="submit" color="primary" size="large">
                             保存活动信息
                           </Button>
                         }
      >
        {/*<Button onClick={handleWxLogin}>微信登录</Button>*/}
        {/*<Button onClick={handlePayClick}>微信支付</Button>;*/}
        <Form.Item name="venue" label="场馆" rules={[{ required: true, message: '场馆不能为空' }]}>
          <Selector
            columns={1}
            options={venues}
            defaultValue={['1']}
          />
        </Form.Item>
        <Form.Item
          name="date"
          label="日期"
          trigger="onConfirm"
          rules={[{ required: true, message: '日期不能为空' }]}
          onClick={(e, datePickerRef) => {
            datePickerRef.current.open();
          }}
        >
          <DatePicker defaultValue={defaultValue.date}>
            {value => value ? dayjs(value).format('YYYY-MM-DD') : '请选择日期'}
          </DatePicker>
        </Form.Item>
        <Form.Item
          name="timeRange"
          label="时间范围"
          trigger="onConfirm"
          rules={[{ required: true, message: '时间不能为空' }]}
          onClick={(e, PickerRef) => {
            PickerRef.current.open();
          }}
        >
          <Picker
            columns={timeColumns}
            defaultValue={defaultValue.timeRange}
          >
            {value => value ? value.map(item => item.label || '未选择').join(' - ') : '请选择日期'}
          </Picker>
        </Form.Item>
        <Form.Item
          name="courtCount"
          label="场地数"
          rules={[{ required: true, message: '场地数不能为空' }]}>
          <Input placeholder="请输入场地数" defaultValue={defaultValue.courtCount} type="number" />
        </Form.Item>
        {/*<Form.Item*/}
        {/*  name="totalBallFee"*/}
        {/*  label="球费"*/}
        {/*  rules={[*/}
        {/*    {*/}
        {/*      pattern: /^(?:\d+)?$/,*/}
        {/*      message: '必须是数字'*/}
        {/*    },*/}
        {/*    {*/}
        {/*      validator: async (_, value) => {*/}
        {/*        if (value && !(value < 100000)) {*/}
        {/*          return Promise.reject('球费必须小于100000');*/}
        {/*        }*/}
        {/*      },*/}
        {/*    },*/}
        {/*  ]}>*/}
        {/*  <Input placeholder="如需人工干预时填写" defaultValue={defaultValue.totalBallFee} type="number" />*/}
        {/*</Form.Item>*/}
        <Form.Item
          name="applicants"
          layout="vertical"
          label="报名队员"
          trigger="onConfirm"
          onClick={(e, SearchPopupRef) => {
            SearchPopupRef.current.open();
          }}
        >
          <UserSelector defaultValue={defaultValue.applicants}>
            {value => value.map((username, idx) => {
              return <Tag
                key={username + idx}
                round
                color="#e7f1ff"
                style={{ fontSize: 14, padding: 6, marginRight: 8, marginBottom: 8, color: '#1677ff', userSelect: 'none', cursor: 'pointer' }}
              >
                {username}
              </Tag>;
            })}
          </UserSelector>
        </Form.Item>
      </Form>}
    </>
  );
}
