import React, { useCallback, useState, useEffect } from 'react';
import { Form, Input, Button, DatePicker, Selector, List, Card } from 'antd-mobile';
import dayjs from 'dayjs';
import { Fetch } from '../utils/fetchData';
import { formatNumberToCurrency } from '../utils/index';
import { useNavigate } from 'react-router-dom';
import useAppStore from '../appStore';
import { RightOutlined } from '@ant-design/icons';

const now = new Date();

export default function FinanceCash() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const fetchUserAccounts = useCallback(async () => {
    const res = await Fetch.GET('/api/users', { limit: 200 });
    res.data = res.data.filter(o => o.balance < 0).map(item => ({ ...item, balanceStr: formatNumberToCurrency(item.balance) }));
    setData(res.data);
  }, []);
  const { setTitle } = useAppStore();

  useEffect(() => {
    setTitle('收款管理');
    fetchFinanceAccounts();
    fetchUserAccounts();
  }, []);

  const [financeAccounts, setFinanceAccounts] = useState({});

  // /api/account/clearBorrowAccounts
  const handleClearAccounts = async () => {
    const res = await Fetch.POST('/api/account/clearBorrowAccounts', {}, {});
    if (res.status === 200) {
      fetchFinanceAccounts();
      fetchUserAccounts();
    }
  };

  const fetchFinanceAccounts = useCallback(async () => {
    const res = await Fetch.GET('/api/financeAccounts');
    console.log(res.data);
    setFinanceAccounts(res.data);
  }, []);

  const venues = [
    { label: '羽毛采购', value: '1' },
    { label: '奖品采购', value: '2' },
    { label: '聚餐', value: '3' }
  ];

  return (
    <div>
      <div className="container">
        <Card style={{ marginBottom: 15 }}>
          <div style={{ fontSize: 18, fontWeight: 500, marginBottom: 10, padding: '0 16px', borderBottom: '1px solid #eee' }}
               className="horizontal-between">
            <span>待收款</span>
            <span style={{ fontSize: 16 }}>
            <span style={{ fontSize: 25, fontFamily: 'BebasNeue', color: 'red' }}>¥{formatNumberToCurrency(financeAccounts.borrowAmount)}</span>
          </span>
          </div>
          <div style={{ fontSize: 18, fontWeight: 500, padding: '0 16px' }} className="horizontal-between">
            <span>现金账户</span>
            <span style={{ fontSize: 16 }}>
            <span style={{ fontSize: 25, fontFamily: 'BebasNeue' }}>¥{formatNumberToCurrency(financeAccounts.cashAmount)}</span>
          </span>
          </div>
        </Card>
        <Card style={{ marginTop: 15 }}>
          <div className="horizontal-between" style={{ margin: '0 0 10px 10px' }}>
            <div style={{ fontSize: 18, fontWeight: 500 }}>待收款用户</div>
            <span style={{ fontSize: 14, fontWeight: 600 }}>共{data.length}人（{Math.abs(financeAccounts.borrowAmount)}元）</span>
          </div>

          <List>
            {data.map(user => (
              <List.Item key={user.name}>
                <div className="horizontal-between">
                  <span>{user.name}</span>
                  <span style={{ color: user.balance < 0 ? 'red' : '' }}>¥{user.balanceStr}</span>
                </div>
              </List.Item>
            ))}
          </List>
        </Card>
      </div>
      <div className="horizontal-center footer">
        <Button block color="primary" onClick={handleClearAccounts}>一键清账</Button>
      </div>
    </div>
  );
}
