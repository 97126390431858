import React, { useCallback, useEffect, useState, useRef } from 'react';
import { List, Card, Button, Selector, SearchBar, Form, Input, Modal, Toast } from 'antd-mobile';
import { Fetch } from '../utils/fetchData';
import { formatNumberToCurrency } from '../utils/index';
import { useNavigate } from 'react-router-dom';
import { RightOutlined, PlusCircleFilled, PayCircleFilled, MoneyCollectFilled } from '@ant-design/icons';
import useAppStore from '../appStore';

function UserList() {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();
  const { setTitle } = useAppStore();
  const formRef = useRef(null);

  const fetchUserAccounts = useCallback(async () => {
    const res = await Fetch.GET('/api/users', { limit: 300 });
    res.data = res.data.map(item => ({ ...item, balanceStr: formatNumberToCurrency(item.balance) }));
    setData(res.data);
  }, []);

  useEffect(() => {
    setTitle('财务管理');
    fetchUserAccounts();
  }, []);

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleAddUser = (user = {}) => {
    Modal.confirm({
      title: `新建用户`,
      content: (
        <>
          <Form initialValues={{
            name:user.name || '',
            nickname:user.nick_name || '',
            nickname2:user.nick_name2 || '',
            gender:(user.gender !== null && user.gender !== undefined) ? [user.gender] : ['1']
          }} ref={formRef}>
            <Form.Item name="name" label="会员名" rules={[{ required: true, message: '会员名不能为空' }]}>
              <Input placeholder="请输入会员名称" defaultValue={''} />
            </Form.Item>
            <Form.Item name="nickname" label="昵称1">
              <Input placeholder="（选填）用于接龙信息匹配" defaultValue={''} />
            </Form.Item>
            <Form.Item name="nickname2" label="昵称2">
              <Input placeholder="（选填）用于接龙信息匹配" defaultValue={''} />
            </Form.Item>
            <Form.Item name="gender" label="性别">
              <Selector
                options={[{ label: '男', value: 1 }, { label: '女', value: 0 }]}
                defaultValue={['1']}
              />
            </Form.Item>
          </Form>
        </>
      ),
      onConfirm: async function (close) {
        const values = formRef.current.getFieldsValue();
        let reqData = { name: values.name, nickname: values.nickname, nickname2: values.nickname2, gender: values.gender[0] };
        if(user.id) {
          reqData.id = user.id
        }
        const res = await Fetch.POST('/api/user', {}, reqData);
        if (res.status === 200) {
          Toast.show({ content: values.name + `会员信息${user.id? '更新': '添加'}成功` });
          formRef.current.resetFields();
          fetchUserAccounts();
        }else {
          Toast.show({ content: res.message });
        }
      }
    });
  };

  return (
    <div className="finance">
      <Card style={{ padding: 0 }}>
        <div className="horizontal-between" style={{ padding: '0 20px', marginBottom: 10 }}>
          <div style={{ fontSize: 18, fontWeight: 500 }}>会员管理</div>
        </div>
        <div className="horizontal-between" style={{ padding: '0 20px', marginBottom: 15 }}>
          <SearchBar
            style={{ width: '80%' }}
            placeholder="输入会员名可筛选"
            value={searchText}
            onChange={v => {
              setSearchText(v);
            }}
          />
          <Button color="primary" fill="outline" size="small" onClick={() => handleAddUser()}>新增</Button>
        </div>

        <List>
          {data.filter(user => {
            if (searchText) {
              let name = user.name.toLowerCase();
              let searchName = searchText.toLowerCase();
              return name.includes(searchName);
            }
            return true;
          }).map(user => (
            <List.Item key={user.name}>
              <div className="user-item">
                <span>{user.name}<span>{user.gender === 1 ? '（男）🍀': user.gender === 0 ?'（女）🌸' : ' 🤔'}</span></span>
                <span style={{ color: user.balance < 0 ? 'red' : '' }}>

                  {/*<span>¥{user.balanceStr}</span>*/}
                  <Button style={{marginLeft: 15}} size="small" onClick={() => handleAddUser(user)}>编辑</Button>
                </span>
              </div>
            </List.Item>
          ))}
        </List>
      </Card>
    </div>
  );
}

export default UserList;
