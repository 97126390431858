export const PAGE_HIERARCHY = {
  '/': {
    key: '',
    level: 1,
    children: []
  },
  '/activity': {
    key: 'activity', // key是指所属tab
    level: 1,        // level是指层级1
    children: ['/activity/:id', '/activity/ended', '/activity/edit/:id']
  },
  '/activity/ended': {
    key: 'activity',
    level: 2,
    parent: '/activity'
  },
  '/activity/:id': {
    key: 'activity',
    level: 2,
    parent: '/activity'
  },
  '/activity/edit/:id': {
    key: 'activity',
    level: 2,
    parent: '/activity'
  },
  '/members': {
    key: 'members',
    level: 1,
    children: ['/members/:id']
  },
  '/finance': {
    key: 'finance',
    level: 1,
    children: ['/finance/:id']
  },
  '/finance/cash': {
    key: 'finance',
    level: 2,
    parent: '/finance'
  },
  '/finance/venue': {
    key: 'finance',
    level: 2,
    parent: '/finance'
  },
  '/finance/borrow': {
    key: 'finance',
    level: 2,
    parent: '/finance'
  },
  '/finance/user/:id': {
    key: 'finance',
    level: 2,
    parent: '/finance'
  },
  '/mine': {
    key: 'mine',
    level: 1,
    children: ['/mine/:id']
  }
  // ... 其他页面
};
