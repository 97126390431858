import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Form, Input, Button, SpinLoading, Selector, Card, Toast } from 'antd-mobile';
import { Fetch } from '../utils/fetchData';
import { formatNumberToCurrency } from '../utils/index';

import { useNavigate } from 'react-router-dom';
import useAppStore from '../appStore';

const now = new Date();

export default function FinanceCash() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [financeAccounts, setFinanceAccounts] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const { setTitle } = useAppStore();
  const formRef = useRef(null);

  const venues = [
    { label: '羽毛球', value: '1' },
    { label: '奖品', value: '2' },
    { label: '聚餐', value: '3' },
    { label: '其他', value: '4' }
  ];

  const accounts = [
    { label: '现金账户', value: '73' },
    { label: '场馆账户', value: '72' },
  ];

  const onFinish = async (values) => {
    const eventData = {
      type: values.type[0],
      amount: values.amount,
      desc: values.desc,
      accountId: values.accountId[0]
    };
    try {
      setLoading(true);
      const res = await Fetch.POST('/api/account/cashExpense', {}, eventData);
      setLoading(false);
      if (res.status === 200) {
        let act = accounts.find(o => o.value === values.accountId[0]);
        Toast.show({ content: act.label + '支出成功' });
        formRef.current.resetFields();
        setLoading(true);
        await fetchFinanceAccounts();
        setLoading(false);
      }
    } catch (error) {
      console.error('Error creating event:', error);
    }
  };

  const fetchTransactions = useCallback(async (accountId) => {
    const res = await Fetch.GET('/api/transactions', { limit: 1000, account_id: accountId, account_id2: 72 });
    setTransactions(res.data);
    console.log(res);
  }, []);

  const fetchFinanceAccounts = useCallback(async () => {
    const res = await Fetch.GET('/api/financeAccounts');
    const accountInfo = res.data;
    setFinanceAccounts(accountInfo);
    await fetchTransactions(accountInfo.cashAccount.id);
  }, []);

  useEffect(() => {
    setTitle('采购');
    fetchFinanceAccounts();
  }, []);

  const defaultValue = {
    accountId: ['73'],
    type: ['1'],
    amount: '',
    desc: ''
  };
  return (
    <div>
      <Card>
        <div style={{ fontSize: 18, fontWeight: 500, marginBottom: 10, padding: '0 10px', borderBottom: '1px solid #eee' }} className="horizontal-between">
          <span>现金账户</span>
          <span style={{ fontSize: 16 }}>
            <span style={{ fontSize: 25, fontFamily: 'BebasNeue' }}>¥{formatNumberToCurrency(financeAccounts.cashAmount)}</span>
          </span>
        </div>
        <div style={{ fontSize: 18, fontWeight: 500, marginBottom: 10, padding: '0 10px' }}
             className="horizontal-between">
          <span>场馆账户</span>
          <span style={{ fontSize: 16 }}>
            <span style={{ fontSize: 25, fontFamily: 'BebasNeue' }}>¥{formatNumberToCurrency(financeAccounts.venueAmount)}</span>
          </span>
        </div>
        <Form onFinish={onFinish}
              ref={formRef}
              initialValues={defaultValue}
              footer={
                <Button loading={loading} block type="submit" color="primary" size="large">
                  确认支出
                </Button>
              }
        >
          <Form.Item name="accountId" label="采购账户" rules={[{ required: true, message: '采购账户不能为空' }]}>
            <Selector
              options={accounts}
              defaultValue={defaultValue.accountId}
            />
          </Form.Item>
          <Form.Item name="type" label="采购类型" rules={[{ required: true, message: '采购类型不能为空' }]}>
            <Selector
              options={venues}
              defaultValue={defaultValue.type}
            />
          </Form.Item>

          <Form.Item name="amount" label="采购金额" rules={[{ required: true, message: '采购金额不能为空' }]} layout="horizontal">
            <Input placeholder="请输入金额" defaultValue={''} type="number" />
          </Form.Item>
          <Form.Item name="desc" label="备注信息">
            <Input placeholder="备注（选填）" defaultValue={''} />
          </Form.Item>
        </Form>
      </Card>
      <div style={{ height: 20 }}></div>
      <Card>
        <div style={{ padding: '0 10px' }}>
          <div style={{ fontSize: 18, fontWeight: 500, margin: '10px 0' }} className="horizontal-between">
            <span>历史账单</span>
          </div>
          {loading && <Card style={{ background: '#F3F4F7', marginBottom: 15, marginTop: 15, fontSize: 14, position: 'relative' }}>
            <div className="horizontal-left">
              <SpinLoading color="primary" style={{ '--size': '24px', marginLeft: 10 }} />
              <span style={{ marginLeft: 20 }}>数据加载中请稍后...</span>
            </div>
          </Card>}
          {/*<span style={{color: '#a9a9a9'}}>暂无历史单据</span>*/}
          {transactions.map(t => {
            return <Card style={{ background: '#F3F4F7', marginBottom: 15, marginTop: 15, fontSize: 14, position: 'relative' }}>
              <div style={{ color: '#333' }}> {t.transaction_detail}</div>
              <div style={{ color: '#a9a9a9' }}> {t.transaction_time}</div>
            </Card>;
          })}
          {transactions.length === 0 && <Card style={{ background: '#F3F4F7', marginBottom: 15, marginTop: 15, fontSize: 14, position: 'relative' }}>
            <span style={{ color: '#a9a9a9' }}>暂无历史单据</span>
          </Card>
          }
        </div>
      </Card>
    </div>
  );
}
