import React from 'react';
import dayjs from 'dayjs';
import './StatusTag.scss'

function StatusTag({ activity }) {
  if (!activity) {
    return null;
  }

  const now = dayjs();
  const activityStartTime = dayjs(`${activity.event_date} ${activity.start_time}:00`);
  const activityEndTime = dayjs(`${activity.event_date} ${activity.end_time}:00`);

  // 检查当前时间是否在活动时间范围内
  if (now.isAfter(activityStartTime) && now.isBefore(activityEndTime)) {
    return <div className="status-tag horizontal-center status-tag-active">活动中</div>;
  }

  // 检查当前时间是否在活动开始时间之前
  if (now.isBefore(activityStartTime)) {
    return <div className="status-tag horizontal-center status-tag-signup">报名中</div>;
  }

  // 检查活动是否已经结束且状态为0
  if (now.isAfter(activityEndTime) && activity.status === 0) {
    return <div className="status-tag horizontal-center status-tag-await">待结算</div>;
  }

  // 检查活动是否已经结束且状态为1
  if (now.isAfter(activityEndTime) && activity.status === 1) {
    return <div className="status-tag horizontal-center status-tag-settled">已结算</div>;
  }

  return null;
}

export default StatusTag;
