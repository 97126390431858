import React, { useState, useImperativeHandle, forwardRef, memo, useCallback, useEffect, useMemo } from 'react';
import { SearchBar, Popup, List, Tag, SafeArea,Button } from 'antd-mobile';
import { CloseOutline } from 'antd-mobile-icons'
import { Fetch } from '../utils/fetchData';
import { List as VirtualizedList, AutoSizer } from 'react-virtualized';

function addNameWithIncrement(namesArray, newName) {
  // 检查是否存在完全相同的名字（不包含数值部分）
  let baseExists = namesArray.some(name => name.trim() === newName);
  let maxNumber = 0; // 用来记录当前最大的数值

  if (baseExists) {
    // 构建一个正则表达式来匹配名字和后面的数值
    let regex = new RegExp("^" + newName.trim() + " \\+(\\d+)$");
    namesArray.forEach(name => {
      let match = name.match(regex);
      if (match) {
        // 如果存在匹配，更新最大数值
        let currentNumber = parseInt(match[1], 10);
        if (currentNumber > maxNumber) {
          maxNumber = currentNumber;
        }
      }
    });

    // 添加新的名字，数值为最大数值+1
    namesArray.push(newName.trim() + " +" + (maxNumber + 1));
  } else {
    // 如果基础名字不存在，直接添加
    namesArray.push(newName);
  }
}

const UserSelector = memo(forwardRef((props, ref) => {
  const { onConfirm, defaultValue } = props;
  const [users, setUsers] = useState([]);
  const [formSelectedUsers, setFormSelectedUsers] = useState(defaultValue)
  const [selectedUsers, setSelectedUsers] = useState(defaultValue);
  const [visible, setVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState(users);

  const fetchUserAccounts = useCallback(async () => {
    const res = await Fetch.GET('/api/users', { limit: 100 });
    setUsers(res.data);
  }, []);

  const filterUsers = useCallback(async () => {
    const filtered = users.filter(item => {
      return ['name', 'nick_name', 'nick_name2']
        .reduce((isMatch, nextKey) => {
          return isMatch || (item[nextKey] && item[nextKey].toLowerCase().includes((searchValue).toLowerCase()));
        }, false);
    });
    setFilteredData(filtered);
  }, [users, searchValue]);

  useEffect(() => {
    fetchUserAccounts();
  }, []);

  useEffect(() => {
    filterUsers();
  }, [users, searchValue]);

  // 使用useImperativeHandle来暴露组件方法
  useImperativeHandle(ref, () => ({
    toggle: () => {
      setVisible(v => !v);
    },
    open: () => setVisible(true),
    close: () => setVisible(false),
  }));

  const handleAddUser = (item) => {
    const list = [...selectedUsers];
    addNameWithIncrement(list, item.name)
    setSelectedUsers(list)
  }
  const handleTagRemove = (value) => {
    const list = [...selectedUsers];
    const tagIndex = list.findIndex(username => username === value)
    list.splice(tagIndex, 1);
    setSelectedUsers(list);
  }

  // 更新搜索结果
  const handleSearch = (value) => {
    setSearchValue(value);
    filterUsers();
  };

  const handleConfirm = () => {
    onConfirm && onConfirm(selectedUsers);
    setFormSelectedUsers(selectedUsers);
    setVisible(false);
  }

  function rowRenderer({ index, key, style, }) {
    const item = filteredData[index];
    return (
      <List.Item
        key={key}
        style={style}
        description={item.nick_name || ''}
        onClick={() => handleAddUser(item)}
      >
        {item.name}
      </List.Item>
    );
  }

  return <>
    <Popup
      visible={visible}
      onMaskClick={() => {
        setSelectedUsers(formSelectedUsers)
        setVisible(false)
      }}
      position="bottom"
    >
      <div
        style={{ padding: '10px 10px 10px 10px', color: '#FFF', background: '#1677ff', fontSize: 14, userSelect: 'none'}}
        className='horizontal-between'
      >
        <span style={{fontSize: 17}}>活动参与人员：共{selectedUsers.length}人</span>
        <Button size='small' style={{'--text-color': '#1677ff'}} onClick={handleConfirm}>
          确认
        </Button>
      </div>
      {selectedUsers.length > 0 && <div style={{ padding: '5px 10px 10px 10px', background: '#FFF' }} className="horizontal-left">
        {selectedUsers.map(username => {
          return <Tag
            round
            key={username}
            color="#e7f1ff"
            style={{ fontSize: 14, padding: 6, margin: 4, color: '#1677ff', userSelect: 'none', cursor: 'pointer' }}
            onClick={() => handleTagRemove(username)}
          >
            {username}
            <CloseOutline style={{ marginLeft: 5 }} />
          </Tag>;
        })}
      </div>}
      <div style={{ padding: 10, background: '#F5F5F5' }}>
        <SearchBar
          placeholder="请输入内容"
          style={{ '--background': '#ffffff' }}
          value={searchValue}
          onChange={handleSearch}
        />
      </div>

      <List>
        <AutoSizer disableHeight>
          {({ width }) => (
            <VirtualizedList
              rowCount={filteredData.length}
              rowRenderer={rowRenderer}
              width={width}
              height={260}
              rowHeight={60}
              overscanRowCount={5}
            />
          )}
        </AutoSizer>
      </List>
      <SafeArea position="bottom" />
    </Popup>
    {props.children && props.children(formSelectedUsers)}
  </>;
}));

export default UserSelector;
