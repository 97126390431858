import React, { useCallback, useState,useEffect, useRef } from 'react';
import { Form, Input, Button, Selector, Card, Toast } from 'antd-mobile';
import { Fetch } from '../utils/fetchData';
import { useParams, useNavigate } from 'react-router-dom';
import { formatNumberToCurrency } from '../utils/index';
import useAppStore from '../appStore';

export default function TransactionList() {
  const { id: accountId } = useParams();
  const [transactions, setTransactions] = useState([])
  const { setTitle } = useAppStore();

  const fetchTransactions = useCallback(async (accountId) => {
    const res = await Fetch.GET('/api/transactions', {limit: 300, account_id: accountId});
    setTransactions(res.data)
    console.log(res)
  }, [])

  useEffect(() => {
    setTitle('历史订单')
    fetchTransactions(accountId)
  }, [])

  return (
    <div>
      <Card>
        <div style={{padding: '0 10px'}}>
          <div style={{ fontSize: 18, fontWeight: 500, margin: '10px 0' }} className='horizontal-between'>
            <span>历史账单</span>
          </div>
          {/*<span style={{color: '#a9a9a9'}}>暂无历史单据</span>*/}
          {transactions.map(t => {
            return <Card style={{ background: '#F3F4F7', marginBottom: 15, marginTop: 15, fontSize: 14, position: 'relative' }}>
              <div style={{color: '#333'}}> {t.transaction_detail}</div>
              <div style={{color: '#a9a9a9'}}> {t.transaction_time}</div>
            </Card>
          })}
          {transactions.length === 0 && <Card style={{ background: '#F3F4F7', marginBottom: 15, marginTop: 15, fontSize: 14, position: 'relative' }}>
            <span style={{ color: '#a9a9a9' }}>暂无历史单据</span>
          </Card>
          }
        </div>
      </Card>
    </div>
  );
}
